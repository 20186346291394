import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import "../../../styles/portfolio.scss";
import alpexLogo from "../../../images/logo/19 c.png";
import { gsap } from "gsap";

export default function AlpexEximPortfolio() {
  var colors = ["#f0f0f0"];
  const pics = [
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic1.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic2.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic3.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic4.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic5.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic6.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic7.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic8.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic9.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic10.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic11.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic12.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic13.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic14.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic15.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic16.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic17.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic18.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic19.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic20.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic21.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic22.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic23.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic24.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic25.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic26.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic27.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic28.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic29.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic30.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic31.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic32.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic33.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic34.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic35.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic36.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic37.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic38.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic39.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic40.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic41.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic42.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic43.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic44.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic45.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic46.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic47.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic48.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic49.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic50.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic51.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic52.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic53.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic54.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic55.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic56.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic57.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic58.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic59.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic60.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic61.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic62.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic63.jpeg",
    },

    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic67.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic68.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic69.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic70.jpeg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/alpex-exim/pic71.jpeg",
    },
  ];

  let col1 = [],
    col2 = [],
    col3 = [];

  for (let i = 0; i < pics.length; i++) {
    if ((i + 1) % 3 === 0) {
      col1.push(pics[i]);
    } else if ((i + 1) % 2 === 0) {
      col2.push(pics[i]);
    } else {
      col3.push(pics[i]);
    }
  }

  console.log(col1);
  console.log(col2);
  console.log(col3);
  const [updateComponent, setUpdateComponent] = useState(0);

  function handleClick() {}

  useEffect(() => {
    var w = window.innerWidth;

    let animboxes = gsap.set(".box", {
      backgroundColor: (i) => colors[Math.floor(Math.random() * 8)],
      y: (i) => (i * w) / 4,
    });

    let anim1 = gsap.to(".wrapper1 .box", {
      duration: 49,
      ease: "none",
      y: "+=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize(
          (y) => parseFloat(y) % ((w / 4) * col1.length + 1)
        ), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
    });
    let anim2 = gsap.to(".wrapper2 .box", {
      duration: 51,
      ease: "none",
      y: "-=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize(
          (y) => parseFloat(y) % ((w / 4) * col2.length + 1)
        ), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
    });
    let anim3 = gsap.to(".wrapper3 .box", {
      duration: 48,
      ease: "none",
      y: "+=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize(
          (y) => parseFloat(y) % ((w / 4) * col3.length + 1)
        ), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
    });

    window.addEventListener("resize", () => {
      anim1.kill();
      anim2.kill();
      anim3.kill();

      animboxes.kill();

      const boxes = document.querySelectorAll(".box");

      boxes.forEach((item) => {
        item.style = "";
      });
      setUpdateComponent(updateComponent + 1);
      w = window.innerWidth;
      animboxes = gsap.set(".box", {
        backgroundColor: (i) => colors[(i % colors.length) + 1],
        y: (i) => (i * w) / 4,
      });

      anim1 = gsap.to(".wrapper1 .box", {
        duration: 49,
        ease: "none",
        y: "+=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize(
            (y) => parseFloat(y) % ((w / 4) * col1.length + 1)
          ), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
      });
      anim2 = gsap.to(".wrapper2 .box", {
        duration: 51,
        ease: "none",
        y: "-=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize(
            (y) => parseFloat(y) % ((w / 4) * col1.length + 1)
          ), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
      });
      anim3 = gsap.to(".wrapper3 .box", {
        duration: 48,
        ease: "none",
        y: "+=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize(
            (y) => parseFloat(y) % ((w / 4) * col3.length + 1)
          ), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
      });
    });
  }, []);

  return (
    <Layout>
      <div className="portfolio">
        <div
          style={{ backgroundColor: "#F07639" }}
          className="portfolio__container"
        >
          <div className="portfolio__client">
            <div
              className="portfolio__client__logo"
              // style={{ backgroundColor: "#082D51" }}
            >
              <img className="" src={alpexLogo}></img>
              {/* <div>Jaycee Power</div> */}
            </div>
          </div>

          <div className="portfolio__wrappers">
            <div class="wrapper wrapper1">
              <div class="boxes">
                {col1.map((item, i) => (
                  <div className="box" key={`col1-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
            <div class="wrapper wrapper2">
              <div class="boxes">
                {col2.map((item, i) => (
                  <div className="box" key={`col2-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
            <div class="wrapper wrapper3">
              <div class="boxes">
                {col3.map((item, i) => (
                  <div className="box" key={`col3-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
